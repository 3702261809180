import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  AppBar,
  InputAdornment,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { CustomTextField } from "../../../Common/InputText/InputField";
import axios from "axios";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import {
  IndianNumberRegex,
  PRAVESH_API,
} from "../../../../Constants/constants";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import fetchApi from "../../../../Apis/fetchApi";
import { useAlert } from "react-alert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import VerificationDialog from "./VerificationDialog";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

function MergedFirstForm({
  step,
  setStep,
  setActiveStep,
  activeStep,
  details,
  setDetails,
  email,
  emailVerified,
  setEmailVerified,
  phoneVerified,
  setPhoneVerified,
  basicInfo,
  setBasicInfo,
  companyInfo,
  fromLoginEmail,
  setFromLoginEmail,
  fromLoginPhone,
  setFromLoginPhone,
  userData,
}) {
  const handleClick = () => {
    setStep(2) && setActiveStep(1);
  };
  const alert = useAlert();
  const theme = useTheme();
  const companyId = useSelector(
    (state) => state?.userDetails?.userDetails?.user_details?.company
  );
  const userDetail = useSelector(
    (state) => state?.userDetails?.userDetails?.user_details
  );

  const prefilled_email = userData?.data?.user_details.email || "";
  const prefilled_name = userData?.data?.user_details.full_name || "";
  const prefilled_phone = userData?.data?.user_details.phone || "";

  const locationvalue = useLocation();
  const { email_history } = locationvalue.state || {};
  const { phone_history } = locationvalue.state || {};
  const { googleloginstatus } = locationvalue.state || {};
  const { fullname_history } = locationvalue.state || {};
  const { firstname_history } = locationvalue.state || {};
  const { lastname_history } = locationvalue.state || {};
  const { step_history } = locationvalue.state || {};
  const history = useHistory();
  const errorValueInitialState = {
    name: "",
    phone: "",
    email: "",
    otp: null,
  };
  const [errorValues, setErrorValues] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [appliedReferral, setAppliedReferral] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [loadingVerifyOtp, setLoadingVerifyOtp] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [referralLoading, setReferralLoading] = useState(false);
  const [showPassErr, setShowPassErr] = useState(false);
  const [checkPwdLength, setCheckPwdLength] = useState(false);
  const [checkCapital, setCheckCapital] = useState(false);
  const [checkDigit, setCheckDigit] = useState(false);
  const [checkNoSpace, setCheckNoSpace] = useState(false);

  const [values, setValues] = React.useState({
    name: googleloginstatus
      ? `${firstname_history || ""} ${lastname_history || ""}`.trim()
      : googleloginstatus === undefined
      ? ""
      : fullname_history || prefilled_name || "",
    email: email_history || prefilled_email || "",
    phone: phone_history || prefilled_phone || "",
    password: "",
    referralCode: "",
    emailVerified: false,
    checkIn: false,
  });

  const [referralMsg, setReferralMsg] = useState("");
  const [referralSuccess, setReferralSuccess] = useState(false);
  const [continueCheck, setContinue] = useState(
    window.localStorage.getItem("email_verified") || details?.basic?.checkIn
      ? true
      : false
  );
  const [session, setSession] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [verified, setVerified] = useState();
  useEffect(() => {
    setVerified({
      phone_verified: userDetail?.phone_verified,
      email_verified: userDetail?.email_verified,
      otpSent: false,
    });
  }, [userDetail]);

  useEffect(() => {
    window.localStorage.removeItem("referralSuccess");
    window.localStorage.removeItem("referralMsg");
    window.localStorage.removeItem("referralApplied");
    window.localStorage.getItem("email_verified");

    setValues({
      ...values,

      phone: details?.basic?.phone,
      password: details?.basic?.password,
      referralCode: details?.basic?.referralCode,
      emailVerified: details?.basic?.emailVerified,
      checkIn: details?.basic?.checkIn,
    });
  }, [details]);

  const onPassWordChange = () => {
    //setShowPassErr(false);
    let isError = false;
    let passwordError = "";

    if (!values?.password) {
      //setShowPassErr(true);
      if (!passwordError) {
        passwordError = "Password length should be atleast 6 character";
      }
      setCheckPwdLength(true);
      setCheckDigit(true);
      setCheckNoSpace(true);
      setCheckCapital(true);
      isError = true;
      setShowPassErr(true);
    }
    if (
      values?.password &&
      !(values?.password.length > 7 && values?.password.length < 21)
    ) {
      if (!passwordError) {
        passwordError = "Password length should be atleast 6 character";
      }
      setCheckPwdLength(true);
      isError = true;
      setShowPassErr(true);
    }
    const str = /(?=.*?[A-Z]).*/;
    if (values?.password && !str.test(values?.password)) {
      if (!passwordError) {
        passwordError = "Password length should be atleast 6 character";
      }
      setCheckCapital(true);
      isError = true;
      setShowPassErr(true);
    }
    const digitRegex = /^(?=.*\d).+$/;

    if (values?.password && !digitRegex.test(values?.password)) {
      if (!passwordError) {
        passwordError = "Password length should be atleast 6 character";
      }
      setCheckDigit(true);
      isError = true;
      setShowPassErr(true);
    }
    if (
      values?.password &&
      values?.password.length > 1 &&
      values?.password.split(" ").length > 1
    ) {
      setCheckNoSpace(true);
      isError = true;
      setShowPassErr(true);
    }
    setErrorValues({
      ...errorValues,
      password: passwordError,
    });

    return isError ? false : true;
  };

  const [changed, setChanged] = useState(0);
  useEffect(() => {
    if (changed > 0) onPassWordChange();
  }, [changed]);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: "" });
    if (prop === "password") {
      setCheckPwdLength(false);
      setCheckDigit(false);
      setCheckNoSpace(false);
      setCheckCapital(false);
      setChanged((prev) => prev + 1);
    }

    setDetails({
      ...details,
      basic: {
        ...details?.basic,
        [prop]: event.target.value,
      },
    });
  };

  const applyReferral = () => {
    const body = {
      referral_code: values?.referralCode,
    };

    setReferralLoading(true);

    axios
      .post(`${PRAVESH_API}company_saas/new/login/apply_referral/`, body)
      .then((res) => {
        if (res?.data?.success) {
          // Update state and localStorage for successful referral application
          setIsApplied(true);
          setReferralMsg(res?.data?.data?.message);
          setReferralSuccess(true);
          setAppliedReferral(values?.referralCode);
          window.localStorage.setItem("referralSuccess", "true");
          window.localStorage.setItem("referralMsg", res?.data?.data?.message);
          window.localStorage.setItem("referralApplied", "true");
        } else {
          // Update state and localStorage for unsuccessful referral application
          setIsApplied(false);
          setReferralMsg(res?.data?.error || "Failed to apply referral.");
          setReferralSuccess(false);
          setAppliedReferral(null);
          window.localStorage.setItem("referralSuccess", "false");
          window.localStorage.setItem("referralMsg", res?.data?.error);
          window.localStorage.setItem("referralApplied", "true");
        }

        setReferralLoading(false);
      })
      .catch((err) => {
        // Handle error case
        setIsApplied(false);
        setReferralMsg("Something went wrong. Please try again!");
        setReferralSuccess(false);
        setAppliedReferral(null);
        setReferralLoading(false);

        // Show error alert
        alert.error("Something went wrong. Please try again!");

        // Update localStorage for error case
        window.localStorage.setItem("referralSuccess", "false");
        window.localStorage.setItem(
          "referralMsg",
          "Something went wrong. Please try again!"
        );
        window.localStorage.setItem("referralApplied", "false");
      });
  };

  const validateForm = () => {
    let isError = false;
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    let passwordError = "";

    if (!values?.name && !fullname_history && !prefilled_name) {
      nameError = "Please enter full name";
      isError = true;
    }
    if (!values?.email && !prefilled_email) {
      emailError = "Please enter valid email address";
      isError = true;
    }
    if (
      (!values?.phone || values.phone.length !== 10) &&
      !phone_history &&
      !prefilled_phone
    ) {
      phoneError = "Please enter a valid phone number";
      isError = true;
    }

    if (step_history !== 1) {
      if (!values?.password) {
        if (!passwordError) {
          passwordError = "Password length should be atleast 6 character";
        }
        setCheckPwdLength(true);
        setCheckDigit(true);
        setCheckNoSpace(true);
        setCheckCapital(true);
        isError = true;
        setShowPassErr(true);
      }

      if (
        values?.password &&
        !(values?.password.length > 7 && values?.password.length < 21)
      ) {
        if (!passwordError) {
          passwordError = "Password length should be atleast 6 character";
        }
        setCheckPwdLength(true);
        isError = true;
        setShowPassErr(true);
      }

      if (values?.password && !/(?=.*?[A-Z]).*/.test(values?.password)) {
        passwordError = "Password must contain at least one uppercase letter";
        setCheckCapital(true);
        isError = true;
        setShowPassErr(true);
      }

      if (values?.password && !/^(?=.*\d).+$/.test(values?.password)) {
        passwordError = "Password must contain at least one digit";
        setCheckDigit(true);
        isError = true;
        setShowPassErr(true);
      }

      if (values?.password && values?.password.includes(" ")) {
        passwordError = "Password should not contain spaces";
        setCheckNoSpace(true);
        isError = true;
        setShowPassErr(true);
      }
    }

    setErrorValues({
      name: nameError,
      email: emailError,
      phone: phoneError,
      password: passwordError,
    });

    return !isError;
  };
  const helper = (data) => {
    if (data.data.rd_code === 60) {
      setBasicInfo(true);
      setEmailVerified(true);
    }

    if (data.data.str_rd_code === "email_ver_false_nav_enter_otp") {
      //setTimerComplete(false);
      setBasicInfo(true);
      // setStep(2);
      setSession(true);
    } else {
      if (data?.data?.code === 2) {
        alert.error(data?.data?.code_text);
      }
    }
  };
  const sendOtp = () => {
    if (
      !details?.basic?.phone ||
      !IndianNumberRegex?.test(details?.basic?.phone)
    ) {
      setErrorValues({
        ...errorValues,
        phone: "Please enter valid phone number",
      });
      return;
    }
    fetchApi({
      url: `${PRAVESH_API}company_saas/dashboard/settings/send_otp/`,
      method: "post",
      alert: alert,
      body: JSON.stringify({
        phone: details?.basic?.phone,
        otp_type: "MOBILE",
      }),
      setLoading: setLoadingOtp,
      callBackFn: helper,
      loginFlow: true,
    });
  };

  const helperVerify = (data) => {
    setVerified({
      ...verified,
      phone_verified: true,
    });
  };

  const [seconds, setSeconds] = React.useState(30);

  React.useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const signup = () => {
    if (validateForm()) {
      if (!continueCheck) {
        alert.error(
          "Please accept GetWork privacy policy, terms and conditions to register."
        );
        return;
      }

      let body = {
        full_name: values?.name
          ? values.name
          : fullname_history || prefilled_name,
        user_email: values?.email || prefilled_email,
        phone: values?.phone ? values.phone : phone_history || prefilled_phone,
        user_password: values?.password || null,
      };

      if (appliedReferral) {
        body["referral_code"] = appliedReferral;
      }
      fetchApi({
        url: `${PRAVESH_API}company_saas/new/login/signup/`,
        method: "post",
        alert: alert,
        body: JSON.stringify(body),

        setLoading: setLoading,
        callBackFn: helper,
        loginFlow: true,
        alert: alert,
      });
    }
  };

  const customHelp = {
    helper: {
      fontFamily: "gilRoy-semibold",
      fontWeight: "500",
      color: "#909090",
      fontSize: "10px",
      marginLeft: 0,
      marginBottom: "-5px",
    },
  };
  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 4px 11px 0px #0000001C",
          borderRadius: "8px",
          p: 2,
          pt: 1,
          [theme.breakpoints.only("xs")]: {
            boxShadow: "none",
            p: 0.5,
          },
        }}
      >
        <Grid container spacing={3.5} style={{ width: "100%", margin: 0 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h3"
              color="inherit"
              sx={{
                fontWeight: "500",
                textAlign: "center",
                mt: 0.5,
                fontFamily: "gilRoy-semibold",
                mb: 1.5,
                [theme.breakpoints.only("xs")]: {
                  fontSize: "18px",
                },
              }}
            >
              Register with us
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
            <CustomTextField
              name="name"
              label="Name *"
              size="small"
              onChange={handleChange("name")}
              error={errorValues?.name ? true : false}
              helperText={errorValues?.name ? errorValues?.name : ""}
              value={
                googleloginstatus
                  ? `${firstname_history || ""} ${
                      lastname_history || ""
                    }`.trim()
                  : details?.basic?.name ||
                    fullname_history ||
                    prefilled_name ||
                    ""
              }
              disabled={googleloginstatus}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
            <CustomTextField
              label="Email ID *"
              name="email"
              // onChange={handleChange("email")}
              value={email_history}
              error={errorValues?.email ? true : false}
              disabled
              helperText={
                errorValues?.email
                  ? errorValues?.email
                  : "An OTP will be sent to this mail for verification"
              }
              size="small"
              FormHelperTextProps={{ style: customHelp.helper }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {(window.sessionStorage.getItem("email_verified") ||
                      values?.emailVerified) && (
                      <CheckCircleIcon sx={{ color: "#0B6623" }} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
            {" "}
            <CustomTextField
              name="phone"
              label="Contact Number *"
              size="small"
              type="number"
              onWheel={() => document.activeElement.blur()}
              onChange={handleChange("phone")}
              value={details?.basic?.phone ?? phone_history ?? prefilled_phone}
              error={Boolean(errorValues?.phone)}
              helperText={
                errorValues?.phone
                  ? errorValues?.phone
                  : "An OTP will be sent to this number for verification"
              }
              FormHelperTextProps={{ style: customHelp.helper }}
              InputLabelProps={{
                shrink: Boolean(
                  details?.basic?.phone ?? phone_history ?? prefilled_phone
                ), // Prevents label overlap
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ display: "grid", marginTop: "10px" }}
          >
            <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
              {step_history !== 1 && (
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: "16px" }} // Ensure spacing consistency
                >
                  <InputLabel
                    sx={{
                      fontSize: "14px",
                      color: "#7e7e7e",
                      paddingTop: "1.2px",
                    }}
                  >
                    Create Password *
                  </InputLabel>

                  <OutlinedInput
                
                    style={{
                      borderRadius: "8px",
                      height: "40px",
                    }}
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange("password")}
                    value={details?.basic?.password ?? ""}
                    onFocus={() => {
                      onPassWordChange();
                      setShowPassErr(true);
                    }}
                    onPointerOut={() => {
                      setCheckPwdLength(false);
                      setCheckDigit(false);
                      setCheckNoSpace(false);
                      setCheckCapital(false);
                      setShowPassErr(false);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Create Password"
                  />
                  {showPassErr && (
                    <Paper
                      sx={{
                        padding: "10px",
                        position: "absolute",
                        zIndex: 100000000000,
                        backgroundColor: "#fff",
                        top: "40px",
                        borderRadius: "8px",
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          left: 44,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      }}
                    >
                      <Typography style={{ marginLeft: "10px" }}>
                        Password must include
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          padding: "5px 15px",
                          color: checkPwdLength ? "#FF0000" : "#34A853",
                        }}
                      >
                        {checkPwdLength ? (
                          <ClearIcon />
                        ) : (
                          <CheckIcon></CheckIcon>
                        )}
                        <Typography
                          style={{
                            marginLeft: "10px",
                            color: checkPwdLength ? "#FF0000" : "#34A853",
                          }}
                        >
                          {" "}
                          8 - 20 Characters
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          padding: "5px 15px",
                          color: checkCapital ? "#FF0000" : "#34A853",
                        }}
                      >
                        {checkCapital ? <ClearIcon /> : <CheckIcon></CheckIcon>}
                        <Typography
                          style={{
                            marginLeft: "10px",
                            color: checkCapital ? "#FF0000" : "#34A853",
                          }}
                        >
                          At least one capital letter
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          padding: "5px 15px",
                          color: checkDigit ? "#FF0000" : "#34A853",
                        }}
                      >
                        {checkDigit ? <ClearIcon /> : <CheckIcon></CheckIcon>}
                        <Typography
                          style={{
                            marginLeft: "10px",
                            color: checkDigit ? "#FF0000" : "#34A853",
                          }}
                        >
                          At least one number
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          padding: "5px 15px",
                          color: checkNoSpace ? "#FF0000" : "#34A853",
                        }}
                      >
                        {checkNoSpace ? <ClearIcon /> : <CheckIcon></CheckIcon>}
                        <Typography
                          style={{
                            marginLeft: "10px",
                            color: checkNoSpace ? "#FF0000" : "#34A853",
                          }}
                        >
                          No spaces
                        </Typography>
                      </div>
                    </Paper>
                  )}
                </FormControl>
              )}
             
             <CustomTextField
  size="small"
  label="Enter Referral Code"
  variant="outlined"
  InputProps={{
    endAdornment: (
      <Button
        color="primary"
        style={{ fontWeight: "500" }}
        onClick={applyReferral}
        disabled={!values.referralCode?.trim()} // Disable if empty
      >
        <Typography
          variant="body2"
          style={{
            color: `${
              referralSuccess ||
              window.localStorage.getItem("referralSuccess")
                ? "#34A853"
                : "#00203F"
            }`,
            cursor: values.referralCode?.trim() ? "pointer" : "not-allowed",
          }}
        >
          {referralLoading ? (
            <CircularProgress style={{ color: "#00203F" }} size={18} />
          ) : isApplied ? (
            "Applied"
          ) : (
            "Apply"
          )}
        </Typography>
      </Button>
    ),
  }}
  value={values.referralCode}
  onChange={(e) => {
    setValues({
      ...values,
      referralCode: e.target.value.toUpperCase(),
    });
    setDetails({
      ...details,
      basic: {
        ...details.basic,
        referralCode: e.target.value.toUpperCase(),
      },
    });
    setIsApplied(false);
    window.localStorage.setItem("referralApplied", false);
  }}
  sx={{
    marginTop: step_history === 1 ? "0px" : "16px", // Adjust margin based on step_history
  }}
/>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                {referralMsg ? (
                  referralSuccess ? (
                    <CheckCircleRoundedIcon style={{ color: "#34A853" }} />
                  ) : (
                    <HighlightOffIcon style={{ color: "#F1511B" }} />
                  )
                ) : null}

                <Typography
                  variant="body2"
                  style={{
                    color: `${
                      referralSuccess ||
                      window.localStorage.getItem("referralSuccess")
                        ? "#34A853"
                        : "#F1511B"
                    }`,
                  }}
                >
                  {referralMsg || window.localStorage.getItem("referralMsg")
                    ? window.localStorage.getItem("referralMsg")
                    : ""}
                </Typography>
              </div>
            </Grid>

            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={continueCheck}
                  onChange={(e) => {
                    setContinue(!continueCheck);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={
                <Typography style={{ marginTop: "14px", fontSize: "14px" }}>
                  By clicking on continue, you agree to GetWork’s
                  <a
                    style={{ color: "#007BFF", fontWeight: "500" }}
                    href="https://getwork.org/terms-and-conditions"
                    target="_blank"
                  >
                    Terms & Conditions{" "}
                  </a>{" "}
                  and{" "}
                  <a
                    style={{ color: "#007BFF", fontWeight: "500" }}
                    href="https://getwork.org/privacy-and-cookie-policy"
                    target="_blank"
                  >
                    Privacy Policy{" "}
                  </a>
                </Typography>
              }
            />

            {/* <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
            <Button
              endIcon={<ArrowForwardRoundedIcon />}
              color="primary"
              variant="contained"
              sx={{
                height: "40px",
                boxShadow: "none",
                fontSize: "14px",
                borderRadius: "8px",
              }}
              onClick={() => {
                setStep(1);
              }}
            >
              Next
            </Button>
          </Grid> */}
          </Grid>
          <AppBar
            color="inherit"
            sx={{
              boxShadow: "none",
              position: "relative",
              marginTop: "10px",
            }}
          >
            <Grid container spacing={3} style={{ width: "100%", margin: 0 }}>
              <Grid xs={12} sm={12} md={12}>
                <Button
                  color="primary"
                  sx={{
                    height: "40px",
                    width: "100%",
                    boxShadow: "none",
                    borderRadius: "8px",
                    display: "flex",
                    fontWeight: "400",
                    fontSize: "15px",
                  }}
                  // disabled={!(values.password && values.email && values.phone)}

                  variant="contained"
                  onClick={() => {
                    basicInfo && emailVerified
                      ? setStep((prev) => prev + 1)
                      : signup();
                  }}
                >
                  {loading ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : (
                    "Next"
                  )}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<ArrowBackIosRoundedIcon />}
                variant="text"
                color="primary"
                sx={{
                  height: "40px",
                  boxShadow: "none",
                  fontWeight: "400",
                  fontSize: "15px",
                }}
                onClick={() => {
                  history.push("/");
                }}
              >
                Back to Sign In
              </Button>
            </Grid>
          </AppBar>
        </Grid>
      </Box>
      {(session || fromLoginEmail) && basicInfo && (
        <VerificationDialog
          prefilled_email={prefilled_email}
          prefilled_phone={prefilled_phone}
          email_history={email_history}
          setStep={setStep}
          details={details}
          setDetails={setDetails}
          phone={false}
          emailVerified={emailVerified}
          setEmailVerified={setEmailVerified}
          phoneVerified={phoneVerified}
          setPhoneVerified={setPhoneVerified}
          setSession={setSession}
        />
      )}
    </>
  );
}

export default MergedFirstForm;
